.NavBar_Container {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 10px 8px 20px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);

  position: fixed;
  /* top: 0; */
  z-index: 99;
  width: 100%;
}

.nav_profilPic {
  border-radius: 5rem;
  height: 7rem;
  margin: 0.5rem;

  cursor: pointer;
}

.nav_OpenMenu_Btn {
  background-color: gold;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.5rem;

  cursor: pointer;

  font-size: 30px;
}

.nav_Text {
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: right;
  font-size: 10px;
}
.nav_Text_Title {
  font-size: 20px;
  font-weight: 700;
}

.nav_MenuItem_Btn {
  background-color: rgb(242, 181, 11, 0.1);
  border-radius: 0 1rem;
  padding: 1.5rem;
  margin: 0.5rem;

  cursor: pointer;

  font-size: 1.5rem;

  transition: 250ms ease-in-out;
}

.nav_MenuItem_Btn:hover {
  background-color: rgba(242, 181, 11, 1);
}
