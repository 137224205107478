.chat-window .chat-body {
  height: calc(600px - (45px + 70px));
  border: 1px solid #383826;
  /* background: #fff; */
  background: rgba(26, 24, 13, 0.7);
  backdrop-filter: blur(5px);

  color: #3b2903;
  border-radius: 1rem 1rem 0rem 0rem;
  padding: 5px;
  /* position: relative; */
}

.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 1px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  /* max-width: 220px; */
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  /* box-shadow: 0px 0px 5px #e4ebf8; */
  border-radius: 5px;

  /* color: white; */
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-end;
}

#you .message-content {
  justify-content: flex-end;
}

#you .ChatBox_SingleMessage_Container {
  background-color: #e2f7bd;
}

#you .message-meta {
  justify-content: flex-end;
  margin-left: 5px;
  margin-top: -20px;
}

#other {
  justify-content: flex-start;
}

#other .message-content {
  justify-content: flex-start;
}

#other .ChatBox_SingleMessage_Container {
  background-color: #fbf8bd;
}

#other .message-meta {
  justify-content: flex-start;
  margin-right: 5px;
  margin-top: -20px;
}

.message-meta #author {
  /* margin-left: 10px; */
  /* font-weight: bold; */
}

#textBoxStyle_admin {
  background: linear-gradient(to right, #12d8fa, #a6ffcb, #a6ffcb);
  /* font-weight: bolder; */
  box-shadow: 0px 0px 5px #a6ffcb;
}
#textBoxStyle_gm {
  background: linear-gradient(to right, #cf8bf3, #fdb99b, #fdb99b);
  /* font-weight: bolder; */
  box-shadow: 0px 0px 5px #fdb99b;
}
#textBoxStyle_participant {
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  box-shadow: 0px 0px 5px #e4ebf8;
}

.ChatBox_ProfilePic {
  border-radius: 5rem;
  height: 1rem;
}

.ChatBox_AttPic {
  border-radius: 0.5rem;
  width: 100px;

  cursor: pointer;
}

.ChatBox_SingleMessage_Container {
  /* background-color: #a6ffcb; */
  padding: 1rem;
  border-radius: 0.5rem;
  /* width: 100%; */
}

.ChatBox_FlexColumn {
  display: flex;
  flex-direction: column;
}
.ChatBox_FlexRow {
  display: flex;
  flex-direction: row;
}

.ChatBox_AuthorText {
  font-size: 12px;
  font-weight: bold;
  color: gainsboro;
  padding: 0rem 0.5rem 0rem 0.5rem;
}

.ChatBox_DatetimeText {
  font-size: 12px;
  font-weight: bold;
  color: gainsboro;
  padding: 0rem 0.5rem 0rem 0.5rem;

  display: flex;
  flex-direction: row;
}

.ChatBox_Comment_Container {
  background-color: rgb(43, 38, 3);
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0rem 0rem 1rem 1rem;
}
